
import { getPartnerDetailsResult } from '@homeserve/od-typings/api';
import { formatPhone } from '@homeserve/vue-components-plugin/src/utils/phoneHelper';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { getCustomerServiceHours } from '../utils/customerServiceHoursHelper';

@Component
export default class Footer extends Vue {
  private i18n: any;
  private year!: number;

  @Getter('partner')
  private partner!: getPartnerDetailsResult;

  get supportPhone(): string {
    return formatPhone(this.partner.supportPhone, '0', true);
  }

  get onBoardingUrl() {
    return this.partner.traderWebsiteUrl;
  }

  get customerServiceHours() {
    return this.partner.customerServiceHours;
  }

  get isHomeConversion() {
    return this.$route.name === 'home-conversion';
  }

  public created() {
    this.year = new Date().getFullYear();
    this.i18n = {
      hotline_title: this.$t('footer.hotline_title'),
      hotline_number: this.$t('navbar.phone_number', {
        phoneNumber: this.isHomeConversion ? '01 70 96 50 63' : this.supportPhone,
      }),
      hotline_fees: this.$t('navbar.phone_fees'),
      hotline_disclaimer: getCustomerServiceHours(this.customerServiceHours),
      service_name: this.$t('footer.service_name'),
      esca_certification: this.$t('footer.esca_certification'),
      who_we_are_link: this.$t('footer.who_we_are_link'),
      join_network_link: this.$t('footer.join_network_link'),
      pro_app_download: this.$t('footer.pro_app_download'),
      app_link_ios: this.$t('footer.app_link_ios'),
      app_link_android: this.$t('footer.app_link_android'),
      onboarding_title: this.$t('footer.onboarding.title'),
      onboarding_join_us: this.$t('footer.onboarding.join_us'),
      links: {
        plumbing: this.$t('footer.links.plumbing'),
        electricity: this.$t('footer.links.electricity'),
        heating: this.$t('footer.links.heating'),
        air_conditionning: this.$t('footer.links.air_conditionning'),
        gas: this.$t('footer.links.gas'),
        help: this.$t('footer.links.help'),
        near_home: this.$t('footer.links.near_home'),
        who_we_are: this.$t('footer.links.who_we_are'),
        terms_and_conditions: this.$t('footer.links.terms_and_conditions'),
        privacy: this.$t('footer.links.privacy'),
        legal_notice: this.$t('footer.links.legal_notice'),
        our_rates: this.$t('footer.links.our_rates'),
        legal_mentions: this.$t('footer.links.legal_mentions'),
        conditions_of_use: this.$t('footer.links.conditions_of_use'),
        sales_conditions: this.$t('footer.links.sales_conditions'),
        privacy_policies: this.$t('footer.links.privacy_policies'),
        cookies_policies: this.$t('footer.links.cookies_policies'),
        cookies_management: this.$t('footer.links.cookies_management'),
      },
      asterisks: {
        homeserve_data: this.$t('footer.asterisks.homeserve_data'),
      },
    };
  }
}
