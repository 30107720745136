import { getPartnerDetailsResult } from '@homeserve/od-typings/api';
import i18n from '../lang';
import { formatHour } from './dateHelpers';

export function getCustomerServiceHours(customerServiceHours: getPartnerDetailsResult['customerServiceHours']) {
  const { week, weekEnd } = customerServiceHours;
  if (weekEnd) {
    if (week.end === weekEnd.end && week.start === weekEnd.start) {
      return i18n.t('footer.hotline_disclaimer_week', {
        start_day: i18n.t('common.date.start_week_day'),
        end_day: i18n.t('common.date.end_week_end_day'),
        start_hour: formatHour(week.start),
        end_hour: formatHour(week.end),
      });
    }
    return i18n.t('footer.hotline_disclaimer_week_and_week_end', {
      start_week_day: i18n.t('common.date.start_week_day'),
      end_week_day: i18n.t('common.date.end_week_day'),
      start_week_hour: formatHour(week.start),
      end_week_hour: formatHour(week.end),
      start_week_end_day: i18n.t('common.date.start_week_end_day'),
      end_week_end_day: i18n.t('common.date.end_week_end_day'),
      start_week_end_hour: formatHour(weekEnd.start),
      end_week_end_hour: formatHour(weekEnd.end),
    });
  }

  return i18n.t('footer.hotline_disclaimer_week', {
    start_day: i18n.t('common.date.start_week_day'),
    end_day: i18n.t('common.date.end_week_day'),
    start_hour: formatHour(week.start),
    end_hour: formatHour(week.end),
  });
}
